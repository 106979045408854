<template>
  <div class="assessmentReport">
    <img class="header_img" src="../../assets/1.png" alt="" />
    <img class="header_text" src="../../assets/2.png" alt="" />
    <div class="text_box">
      <div class="">
        <img src="../../assets/39.png" alt="" />
        <span>无重大事故</span>
      </div>
      <div>
        <img src="../../assets/40.png" alt="" />
        <span>无火烧事故</span>
      </div>
      <div>
        <img src="../../assets/41.png" alt="" />
        <span>无泡水事故</span>
      </div>
    </div>
    <div class="header_content">
      <div class="car_box">
        <img class="car_img" :src="carInfo.firstImage" alt="" />
        <div class="car_right">
          <span class="car_name">{{carInfo.vehicleName}}</span>
          <span class="car_vin">VIN：{{carInfo.vin}}</span>
        </div>
      </div>

      <div class="vehicleInformation">
        <span class="vehicleInformation_title">基本信息</span>
        <div class="vehicleInformation_box">
          <div class="vehicleInformation_box_item">
            <span>{{ carInfo.registrationDate || "-" }}</span>
            <span>注册日期</span>
          </div>
          <div class="vehicleInformation_box_item">
            <span>{{ carInfo.drivenDistance || "-" }}公里</span>
            <span>车显里程</span>
          </div>
          <div class="vehicleInformation_box_item">
            <span>{{ carInfo.emissionStdName || "-" }}</span>
            <span>排放标准</span>
          </div>
          <div class="vehicleInformation_box_item">
            <span>{{ carInfo.exteriorColorName || "-" }}</span>
            <span>颜色</span>
          </div>
          <div class="vehicleInformation_box_item">
            <span>{{ carInfo.weptVolume || "-" }}</span>
            <span>排量</span>
          </div>
          <div class="vehicleInformation_box_item">
            <span>{{ carInfo.transmissionType || "-" }}</span>
            <span>变速器</span>
          </div>
        </div>
      </div>

      <div class="vehicleInformation">
        <span class="vehicleInformation_title">评分</span>

        <div class="gradeBox">
          <img class="gradeBoxIcon" src="../../assets/44.png" alt="">
          <span class="gradeBoxTip">评估检测报告：</span>
          <span class="gradeBoxResult"><em>{{ carInfo.evaluateDetectLRevel }}</em>({{ carInfo.evaluateDetectResult }})</span>
        </div>

        <div class="gradeLine"></div>

        <div class="car_info">
          <div class="car_info_top">
            <div class="car_info_item">
              <span>{{ carInfo.exteriorInteriorScore }}</span>
            </div>

            <div class="car_info_item">
              <span>{{ carInfo.vehicleEvaluateRevel }}</span>
            </div>
          </div>
          <div class="car_info_bottom">
            <div class="car_info_item">
              <span>外观与内饰的评级得分</span>
            </div>
            <div class="car_info_item">
              <span>车辆评估结果</span>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="ml_page_bottom">
      <assessmentReportCom v-if="checkList.length > 0" :checkArr="checkList" :flagId="flagId" :areaid="areaid" />
    </div>
  </div>
</template>

<script>
import assessmentReportCom from "@/components/assessmentReportCom";

import { getEvaluateReport } from "@/utils/api";
export default {
  components: {
    assessmentReportCom,
  },
  data() {
    return {
      id: "",
      type: "vehicle",
      areaid: null,

      flagId: null,
      carInfo: {},
      checkList: [],
    };
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.areaid = this.$route.query.areaid;
    this.getEvaluateReport();
  },
  methods: {
    getEvaluateReport() {
      const that = this;
      getEvaluateReport({
        vehicleId: that.id,
        type: that.type,
        isNoAuthorization: true,
      }).then((res) => {
        if (res.data) {
          let data = res.data.detectList;
          that.flagId = that.areaid ? that.areaid : data[0].areaId;
          that.carInfo = res.data;
          that.checkList = data;
        }
      });
    },
  },
};
</script>
<style lang='scss'>
.assessmentReport {
  position: relative;
  background: #f3f5f7;
  overflow: hidden;

  .header_img {
    width: 100%;
    height: 211px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }


  .header_text {
    display: block;
    height: 28px;
    margin: 0 auto;
    margin-top: 30px;
    position: relative;
  }

  .text_box {
    display: flex;
    justify-content: space-around;
    position: relative;
    box-sizing: border-box;
    padding: 0 15px;
    margin-top: 25px;
    div{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    img {
      display: block;
      width: 28px;
      height: 28px;
    }
    span{
      font-family: PingFang-SC-Regular;
      font-size: 14px;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10px;
    }
  }

  .header_content {
    width: 100%;
    background: #ffffff;
    border-radius: 16px;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 15px;
    position: relative;
    .car_box {
      display: flex;
      justify-content: left;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      .car_img {
        width: 105px;
        height: 78.75px;
        border-radius: 4px;
        margin-right: 20px;
      }
      .car_right {
        width: calc( 100% - 125px );
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .car_name {
          font-family: PingFangSC-Semibold;
          font-size: 16px;
          color: #292d33;
          font-weight: 600;
        }
        .car_vin {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #292d33;
        }
      }
    }

    .vehicleInformation {
      background: #ffffff;
      border-radius: 16px;
      margin-top: 15px;
      .vehicleInformation_title {
        display: block;
        font-family: PingFang-SC-Medium;
        font-size: 14px;
        color: #292d33;
        font-weight: bold;
      }
      .gradeBox{
        width: 100%;
        height: 28px;
        box-sizing: border-box;
        background-image: linear-gradient(270deg, rgba(50,127,255,0.00) 0%, rgba(50,127,255,0.1) 100%);
        border-radius: 14px;
        margin-top: 15px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        .gradeBoxIcon{
          height: 15px;
          width: 15px;
        }
        .gradeBoxTip{
          font-family: PingFang-SC-Regular;
          font-size: 12px;
          color: #292D33;
          font-weight: 400;
          margin-left: 7px;
        }
        .gradeBoxResult{
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          color: #327FFF;
          font-weight: 400;
          em{
            font-style: normal;
            font-weight: 600;
          }
        }
      }

      .gradeLine{
        width: 100%;
        height: 1px;
        background: #e9e9e9;
        margin-top: 20px;
      }

      .car_info {
        width: 100%;
        margin-top: 15px;
        .car_info_top {
          display: flex;
          .car_info_item {
            width: 50%;
            display: inline-block;
            text-align: center;
            span {
              display: block;
              font-family: PingFang-SC-Semibold;
              font-size: 16px;
              color: #292D33;
              line-height: 22px;
              font-weight: 600;
            }
          }
        }
        .car_info_bottom {
          margin-top: 10px;
          .car_info_item {
            width: 50%;
            display: inline-block;
            text-align: center;
            span {
              margin-top: 5px;
              font-family: PingFang-SC-Regular;
              font-size: 12px;
              color: #979797;
              font-weight: 400;
            }
          }
        }
      }

      .vehicleInformation_box {
        padding: 0 5px;
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;

        .vehicleInformation_box_item {
          display: flex;
          flex-direction: column;
          margin-top: 15px;
          &:first-child,
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(6) {
            width: 25% !important;
          }
          &:nth-child(2),
          &:nth-child(5) {
            width: 50% !important;
          }
          span {
            display: block;
            text-align: center;
            font-family: PingFang-SC-Regular;
          }
          span:first-child {
            font-size: 14px;
            color: #292d33;
          }
          span:last-child {
            margin-top: 8px;
            font-size: 12px;
            color: #979797;
          }
        }
      }
    }
    
  }

  .ml_page_bottom{
    background: #ffffff;
    padding-top:12px;
    border-radius:16px;
  }
}
</style>